/* LoadingSpinner.css */

.spinner {
    border: 6px solid rgba(0,0,0,0.1);  /* Light grey border */
    border-top: 6px solid #3498db;  /* Blue border */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* Additional styles for centering the spinner on the page or over content */

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
}

/* Use the following if you want to overlay the spinner over content */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to make sure it covers other content */
}
