.auth-form-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .auth-form-title {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.5rem;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
  }
  
  .auth-form input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .auth-form button {
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-form button:hover {
    background-color: #0056b3;
  }
  
  .auth-form-error {
    color: #dc3545;
    margin-top: 1rem;
    text-align: center;
  }
  
  .auth-form-switch {
    text-align: center;
    margin-top: 1rem;
  }
  
  .auth-form-switch a {
    color: #007bff;
    text-decoration: none;
  }
  
  .auth-form-switch a:hover {
    text-decoration: underline;
  }