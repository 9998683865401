/* CreateOrderForBusiness.css */

.create-order-for-business-container {
  direction: rtl;
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.create-order-for-business-container h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.create-order-for-business-container .form-group {
  margin-bottom: 20px;
  text-align: right;
}

.create-order-for-business-container label {
  display: block;
  font-size: 1.1em;
  margin-bottom: 5px;
  color: #333;
}

.create-order-for-business-container input[type="text"],
.create-order-for-business-container input[type="file"],
.create-order-for-business-container select,
.create-order-for-business-container input[type="time"] {
  width: 100%;
  padding: 10px;
  font-size: 1.1em;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.create-order-for-business-container input:focus,
.create-order-for-business-container select:focus {
  outline: none;
  border-color: #007bff;
}

.create-order-for-business-container button {
  padding: 12px 25px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 20px;
}

.create-order-for-business-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.create-order-for-business-container button:hover:not(:disabled) {
  background-color: #218838;
}

.create-order-for-business-container button:disabled:hover {
  background-color: #ccc;
}

/* Payment method selection styles */
.payment-method-explanation {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
  text-align: right;
}

.payment-method-selection {
  margin-bottom: 20px;
  text-align: right;
}

.payment-method-selection label {
  display: block;
  font-size: 1.1em;
  margin-bottom: 10px;
  cursor: pointer;
}

.payment-method-selection input[type="radio"] {
  margin-left: 10px;
  transform: scale(1.2);
}

/* Payment apps selection styles */
.payment-apps-explanation {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #333;
  text-align: right;
}

.payment-apps-selection {
  margin-bottom: 20px;
  text-align: right;
}

.payment-apps-selection label {
  display: block;
  font-size: 1em;
  margin-bottom: 10px;
  cursor: pointer;
}

.payment-apps-selection input[type="checkbox"] {
  margin-left: 10px;
  transform: scale(1.2);
}

/* Notice about phone number */
.phone-number-notice {
  font-size: 0.9em;
  color: #555;
  margin-top: -10px;
  margin-bottom: 20px;
  text-align: right;
}

/* Request Address styles */
.request-address {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: right;
  font-size: 1.1em;
  color: #333;
}

.request-address label {
  display: block;
  font-size: 1em;
  margin-bottom: 10px;
  cursor: pointer;
}

.areas-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.area-checkbox {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

/* Optional: Style the checkboxes and labels */
.area-checkbox input[type="checkbox"] {
  margin-left: 5px;
}

.form-group input[type="checkbox"] {
  margin-left: 10px;
  transform: scale(1.2);
}

.request-address input[type="checkbox"] {
  margin-left: 10px;
  transform: scale(1.2);
}

/* Order Type Selection */
.order-type-selection {
  margin-bottom: 20px;
  text-align: right;
}

.order-type-selection p {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.order-type-selection label {
  display: block;
  font-size: 1.1em;
  margin-bottom: 10px;
  cursor: pointer;
}

.order-type-selection input[type="radio"] {
  margin-left: 10px;
  transform: scale(1.2);
}

/* Schedule Selection */
.order-schedule {
  margin-bottom: 20px;
  text-align: right;
}

.order-schedule h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.day-schedule {
  margin-bottom: 10px;
}

.day-schedule label {
  font-size: 1.1em;
  cursor: pointer;
}

.day-schedule input[type="checkbox"] {
  margin-left: 10px;
  transform: scale(1.2);
}

.time-range {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.time-range input[type="time"] {
  width: 45%;
  margin: 0 5px;
}

.time-range span {
  margin: 0 5px;
}

/* Ending Time Explanation */
.ending-time-explanation {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #333;
  text-align: right;
}

.duration-select {
  width: 100%;
  padding: 10px;
  font-size: 1.1em;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.duration-select:focus {
  outline: none;
  border-color: #007bff;
}

/* Media queries for responsiveness */
@media (max-width: 600px) {
  .create-order-for-business-container {
    padding: 15px;
  }

  .create-order-for-business-container h1 {
    font-size: 1.8em;
  }

  .create-order-for-business-container input,
  .create-order-for-business-container select {
    font-size: 1em;
  }

  .create-order-for-business-container button {
    font-size: 1em;
  }

  .payment-method-selection label,
  .payment-apps-selection label,
  .order-type-selection label {
    font-size: 1em;
  }
}
