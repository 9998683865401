/* LandingPage.css */

/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
body {
  /* font-family: 'Alef', sans-serif; */
  /* color: #333; */
  /* direction: rtl; */
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

.landing-page {
  overflow-x: hidden;
}

/* Navigation */
.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 20px 40px;
  background: transparent;
  transition: background 0.3s;
}

.navigation.scrolled {
  background: rgba(0, 0, 0, 0.7);
}

.navigation .logo {
  height: 40px;
  margin-left: auto;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  margin-left: 30px;
  font-size: 16px;
  color: #ffffff;
  transition: color 0.3s;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.7);
}

.nav-links a:hover {
  color: #ffffff;
}

/* Hero Section */
.hero {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Reduce opacity for a lighter shade */
  pointer-events: none; /* Ensures the overlay doesn't block clicks */
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 200px;
  text-align: center;
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.2);
  direction: rtl;
}

.hero-content h1 {
  font-size: 56px;
  margin-bottom: 30px;
}

.hero-content p {
  font-size: 24px;
  margin-bottom: 40px;
}

.cta-button {
  background-color: #ff8c00;
  color: #ffffff;
  padding: 15px 40px;
  border-radius: 50px;
  font-size: 18px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #e67e22;
}

/* Feature Sections */
.feature-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  pointer-events: none; /* Ensures the overlay doesn't block clicks */
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.2);
  direction: rtl;
}

.feature-section.reverse {
  flex-direction: row-reverse;
}

.feature-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.feature-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}

.feature-content {
  position: absolute;
  max-width: 600px;
  padding: 20px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.feature-content h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

.feature-content p {
  font-size: 20px;
}

/* Video Section */
.video-section {
  background: linear-gradient(135deg, #ff8c00, #e67e22);
  padding: 100px 20px;
  text-align: center;
  color: #fff;
}

.video-section h2 {
  font-size: 36px;
  margin-bottom: 40px;
}

.video-container {
  max-width: 800px;
  margin: 0 auto;
}

.video-container iframe {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 10px;
}

/* CTA Section */
.cta-section {
  background: #333;
  color: #fff;
  padding: 100px 20px;
  text-align: center;
}

.cta-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 20px;
  margin-bottom: 30px;
}

.cta-section .cta-button {
  background-color: #ff8c00;
  color: #fff;
  padding: 15px 40px;
  border-radius: 50px;
  font-size: 18px;
}

.cta-section .cta-button:hover {
  background-color: #e67e22;
}

/* Footer */
.footer {
  background-color: #222;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.footer-logo {
  width: 80px;
  margin-bottom: 20px;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-links a {
  color: #fff;
  margin: 0 15px;
  font-size: 16px;
}

.footer-links a:hover {
  color: #ddd;
}

.footer p {
  font-size: 14px;
  color: #ccc;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content p {
    font-size: 18px;
  }

  .feature-content h2 {
    font-size: 28px;
  }

  .feature-content p {
    font-size: 16px;
  }

  .video-section h2 {
    font-size: 28px;
  }

  .video-container iframe {
    height: 250px;
  }

  .cta-section h2 {
    font-size: 28px;
  }

  .cta-section p {
    font-size: 16px;
  }
}

/* Scroll-based Background Color Transition */
body {
  background: linear-gradient(180deg, #fff, #f0f0f0);
}

body.scrolled {
  background: linear-gradient(180deg, #f0f0f0, #fff);
}
