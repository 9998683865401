/* Container for the payment instructions page */
.payment-instructions-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    direction: rtl; /* For Hebrew text */
    text-align: center;
}

.payment-instructions-container h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

.payment-instructions-container h2 {
    font-size: 1.8em;
    /* color: #d9534f; */
    margin-bottom: 15px;
}

.payment-method {
    margin-top: 30px;
    text-align: right;
}

.payment-method h3 {
    font-size: 1.5em;
    color: #555;
    margin-bottom: 10px;
}

.payment-method p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
}

.payment-method .phone-number {
    font-size: 1.5em;
    font-weight: bold;
    color: #28a745;
}

.payment-method a {
    font-size: 1.2em;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}

.payment-method a:hover {
    color: #0056b3;
}

.buttons-container {
    margin-top: 40px;
}

.buttons-container .home-button {
    padding: 12px 25px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.buttons-container .home-button:hover {
    background-color: #218838;
}
