.producers-header {
  text-align: center;
  color: #333;
  font-size: 2rem; /* Example size, adjust based on design needs */
  margin-top: 20px;
  margin-bottom: 40px;
}

.producers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px; /* Increased space between cards */
  padding: 20px;
  padding-top: 80px;
}

.producer-item {
  width: 300px; /* Fixed width for consistency */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.producer-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0,0,0,0.2);
}

.producer-item img {
  width: 100%;
  height: 250px;
  object-fit: cover; /* Ensures images cover the area properly */
}

.producer-item p {
  padding: 1px;
  margin: 0;
  font-size: 16px;
  color: #333;
  /* text-align: right; Align text to the right */
}

.producer-item p span {
  direction: ltr; /* Ensure the content inside span is left-to-right */
  unicode-bidi: embed; /* Embed the directionality */
}
