@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Scripto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');


body {
  font-family: 'Alef', sans-serif;
}

.navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  padding: 10px 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: none;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  width: 100%;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 30px;
  color: #333;
  padding: 12px;
  position: absolute;
  right: 10px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-list li {
  margin: 0 20px;
}

.menu-list a, .menu-list button {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s ease;
  padding: 10px 20px;
}

.menu-list a:hover, .menu-list button:hover {
  color: #007BFF;
}

.logout-button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s ease;
}

.logout-button:hover {
  color: #007BFF;
}

@media (max-width: 768px) {
  .navbar {
    padding: 5px 10px;
    position: relative;
    z-index: 1001; /* Ensure navbar is above the overlay */  }

  .title {
    display: block;
    font-size: 24px;
    margin-left: 15px; /* Add left margin */
    cursor: pointer; /* Make it look clickable */
    font-family: 'Lobster', sans-serif; /* Apply the new font here */
  }

  .title a {
    color: #333;
    text-decoration: none;
    font-family: inherit; /* Ensure the link inherits the font */
  }

  .hamburger {
    display: block;
    font-size: 25px;
    padding: 8px;
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Just below the menu */
  }

  .menu-list {
    display: none;
    position: fixed;
    top: 50px; /* Adjust this value to match your navbar height */
    left: 0;
    right: 0;
    bottom: 0; /* Extend to the bottom of the screen */
    background-color: #f8f9fa;
    flex-direction: column;
    width: 100%;
    z-index: 1000;
    overflow-y: auto; /* Enable vertical scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scroll-behavior: smooth;
    }

  .menu-list.open {
    display: flex;
    animation: slideDown 0.3s ease-out forwards;
  }

  .menu-list::after {
    content: '';
    display: block;
    height: 10px;
    background: linear-gradient(to top, #f8f9fa, transparent);
    position: sticky;
    bottom: 0;
  }
  
  .menu-list li {
    width: 100%;
    padding: 10px 5px;
    border-bottom: 1px solid #e7e7e7;
    text-align: center;
  }

  .menu-list a, .menu-list button {
    display: block;
    color: #333;
  }

  .menu-list button {
    width: 100%;
    background: transparent;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}