/* src/components/ProductDetails.css */

.product-details-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-image-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .product-details-main-image {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .product-details-thumbnail-row {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .product-details-thumbnail {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover;
    transition: transform 0.2s, border 0.2s;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  }
  
  .product-details-thumbnail.selected,
  .product-details-thumbnail:hover {
    transform: scale(1.05);
    border: 2px solid #007bff;
  }
  
  .product-details-info-section {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
  }
  
  .product-details-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .product-details-price {
    font-size: 1.5em;
    color: #28a745;
    margin-bottom: 20px;
  }
  
  .product-details-description {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .product-details-options h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .product-details-options ul {
    list-style-type: none;
    padding: 0;
  }
  
  .product-details-options li {
    color: #555;
    margin-bottom: 5px;
  }
  
  .product-details-add-to-cart-button {
    padding: 12px 25px;
    font-size: 1em;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .product-details-add-to-cart-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .product-details-container {
      flex-direction: row;
      gap: 40px;
    }
  }
  