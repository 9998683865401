.my-products-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.my-products-header {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2em;
  color: #333;
}

.add-product-button {
  display: block;
  margin: 0 auto 30px auto;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 30%;
}

.add-product-button:hover {
  background-color: #0056b3;
}

.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 20px;
}

.product-item {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Make sure content is spaced properly */
}

/* Adjust the size of product images inside the grid */
.product-images-slider {
  width: 100%;
  margin-bottom: 10px; /* Spacing between slider and other content */
}

.product-item h3 {
  margin-top: 0;
  font-size: 1.5em;
}

.product-item p {
  margin: 5px 0;
  color: #555;
}

.empty-state {
  text-align: center;
  margin-top: 50px;
}

.empty-state-image {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.empty-state h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.empty-state p {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.primary-button {
  padding: 10px 20px;
  background-color: #28a745; /* Green color */
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.primary-button:hover {
  background-color: #218838;
}

.product-images {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.product-image {
  width: 100px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.edit-product-button {
  background-color: #218838;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: 20%;
}

.edit-product-button:hover {
  background-color: #0056b3;
}

.delete-product-button {
  background-color: #dc3545; /* Red color for delete */
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: 20%;
}

.delete-product-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

.floating-action-button {
  bottom: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 15px 25px;
  font-size: 18px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 100;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.floating-action-button:hover {
  background-color: #218838;
}

/* Disabled state */
.floating-action-button:disabled,
.floating-action-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.6; /* Makes the button look slightly transparent */
}

.selected {
  border: 2px solid #28a745; /* Highlight selected product */
}

.checkout-button {
  position: absolute; /* Absolute position to place it on the right */
  right: 20px; /* Align to the right */
  top: 20px;
  width: 20px; /* Make the checkbox bigger */
  height: 20px; /* Adjust size for better visibility */
  cursor: pointer;
  transform: scale(1.5); /* Scale it to increase size */
}

/* Slider container */
.product-images-slider {
  width: 100%;
  margin-top: 10px;
}

/* Individual slides */
.product-images-slider .slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* Images inside the slider */
.product-images-slider img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

/* Adjust dots position if needed */
.product-images-slider .slick-dots {
  bottom: -25px; /* Adjust the value as needed */
}

/* Adjust arrows for RTL */
.product-images-slider .slick-prev,
.product-images-slider .slick-next {
  z-index: 1;
}
.product-images-slider .slick-slide div {
  display: flex;
  justify-content: center;
}

.product-images-slider img {
  width: 100%;
  height: auto;
}


@media (max-width: 600px) {
  .product-item h3 {
      font-size: 1.2em;
  }
  .primary-button {
      width: 100%;
  }

  .products-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust grid for smaller screens */
  }
}
