.footer {
    background-color: #333;
    color: white;
    padding: 40px 20px 20px;
    font-family: Arial, sans-serif;
    direction: rtl; /* Set the main direction to RTL */
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
}

.footer-section {
    flex: 1;
    margin: 20px;
    min-width: 200px;
    text-align: right; /* Align text to the right */
}

.footer-section h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #f0a500;
}

.footer-section p, .footer-section ul, .footer-section a {
    font-size: 0.9rem;
    color: #ddd;
    text-decoration: none;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section a:hover {
    color: #f0a500;
    text-decoration: underline;
}

.social-links {
    display: flex;
    justify-content: flex-end; /* Align social links to the right */
}

.social-links a {
    margin-left: 15px; /* Change margin-right to margin-left for RTL */
}

.footer-bottom {
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #555;
}

.footer-bottom p {
    margin: 0;
    font-size: 0.8rem;
    color: #aaa;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
    }

    .footer-section {
        margin: 10px 0;
    }

    .social-links {
        justify-content: flex-start; /* Align social links to the right on mobile */
    }
}