/* OrderSummary.css */
.order-summary-container {
    padding: 30px;
    max-width: 960px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.12);
    font-family: 'Roboto', sans-serif; /* More modern font */
    text-align: center;

}

.loading, .no-data {
    text-align: center;
    font-size: 20px;
    color: #666;
}

.order-id, .total-amount, .minimum-required {
    color: #333;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 18px;
}

.order-member {
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-top: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.order-member h3 {
    color: #333;
    font-size: 20px;
    margin-bottom: 15px;
}

.order-member ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.order-member li {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
    line-height: 1.5;
}

.progress-bar-container {
    margin: 25px 0 35px;
    height: 25px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 8px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.progress-bar {
    height: 100%;
    border-radius: 8px;
    text-align: center;
    line-height: 25px; /* Align text vertically */
    background-image: linear-gradient(45deg, #4caf50 25%, #8bc34a 50%, #cddc39 75%); /* Gradient effect */
    transition: width 0.4s ease-in-out; /* Smooth transition for width */
    color: #fff; /* White text */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .order-summary-container {
        padding: 20px;
    }

    .order-member {
        padding: 15px;
    }

    .progress-bar-container {
        height: 20px;
    }

    .progress-bar {
        line-height: 20px;
    }
}
