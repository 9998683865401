.confirmation-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    text-align: center;
}

h1, h2, h3 {
    color: #333;
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.quantity-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.quantity-controls button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: auto;
}

.quantity-controls span {
    font-size: 16px;
    padding: 5px 10px;
    margin: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 50px;
    text-align: center;
}

.user-details-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    text-align: right; /* Align text to the right */
    direction: rtl; /* Ensure right-to-left input for Hebrew */
}

.input-group.invalid input {
    border-color: red;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.popup-content h3 {
    margin-bottom: 20px;
}

.popup-content button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
}

.popup-content button:hover {
    background-color: #45a049;
}

.button-container {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

/* .cancel-button, .submit-button {
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px 20px;
    font-size: 16px;
    flex: 0 1 200px; 
    height: 50px; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    text-align: center; 
} */

.cancel-button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 200px;

}

.sub-button{
    background-color: #4CAF50; /* Add a default background color for the submit button */
    width: 200px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;

}

.submit-button {
    background-color: #4CAF50; /* Add a default background color for the submit button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 200px;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
    background-color: #45a049;
}

.cancel-button:hover {
    background-color: #d32f2f;
}


.payment-iframe-container {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}

.terms-agreement {
    margin: 20px 0;
    text-align: right;
}

.terms-agreement input[type="checkbox"] {
    margin-left: 10px;
    margin-right: 10px;

}

.terms-agreement label {
    font-size: 14px;
}

.terms-agreement a {
    color: #4CAF50;
    text-decoration: underline;
}