.dashboard-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.orders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.order-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.order-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.order-name {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.order-card p {
  margin: 10px 0;
  color: #666;
}

.order-card-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.view-order-btn, .copy-link-btn {
  flex: 1;
  padding: 8px;
  font-size: 0.9em;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.view-order-btn {
  background-color: #007BFF;
}

.view-order-btn:hover {
  background-color: #0056b3;
}

.copy-link-btn {
  background-color: #28a745;
}

.copy-link-btn:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .orders-grid {
      grid-template-columns: 1fr;
  }

  .view-order-btn, .copy-link-btn {
      padding: 6px;
  }
}
