/* Container for the entire available orders page */
.available-order-forms-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  background: #f9f9f9;
  direction: rtl;
  align-items: center;
}

/* Header styling */
.available-order-forms-header {
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  color: #333;
  font-weight: bold;
}

/* Message when there are no available orders */
.no-orders-message {
  text-align: center;
  font-size: 20px;
  color: #777;
  margin-top: 50px;
}

/* Grid layout for the list of orders */
.available-order-forms-container .orders-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual order card */
.order-item {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  text-align: right;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Hover effect for the order card */
.order-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

/* Image styling inside order item */
.producer-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Order title */
.order-item h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}

/* Paragraph styling inside the order card */
.order-item p {
  margin: 8px 0;
  font-size: 16px;
  color: #555;
  line-height: 1.4;
}

/* Bold labels inside paragraphs */
.order-item p strong {
  color: #333;
}

/* Time remaining styling */
.order-item .time-remaining {
  font-size: 18px;
  font-weight: bold;
  color: #d9534f;
}

/* Container for buttons */
.order-item .button-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

/* Button styles */
.order-item button {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* margin-bottom: 20px; */
}

/* Button hover effect */
.order-item button:hover {
  background-color: #0056b3;
}

/* "Take Order" button styling */
.order-item .take-order-btn {
  background-color: #28a745;
}

.order-item .take-order-btn:hover {
  background-color: #218838;
}

/* Filter container styles */
.filter-container {
  margin-bottom: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-container label {
  font-size: 16px;
  color: #333;
}

.filter-container select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
  .available-order-forms-header {
      font-size: 24px;
  }

  .order-item h3 {
      font-size: 16px;
  }

  .order-item p {
      font-size: 12px;
  }

  .order-item .time-remaining {
      font-size: 12px;
  }

/* Individual order card */
.order-item {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  text-align: right;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}
}

/* High-resolution displays adjustments */
@media (min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2) {
  .producer-image {
      image-rendering: -webkit-optimize-contrast;
  }
}

/* Style adjustments when the time remaining is less than an hour */
.order-item .ending-soon {
  color: #e67e22;
}
