.payment-success-container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    justify-content: center;
    align-items: center;
}

.buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

h1 {
    color: #4CAF50;
    margin-bottom: 20px;
}

p {
    margin-bottom: 15px;
    line-height: 1.5;
}

.action-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

.register-button, .signin-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 150px;
    justify-content: center;
}

.register-button {
    background-color: #4CAF50;
}

.signin-button {
    background-color: #008CBA;
}

.register-button:hover {
    background-color: #45a049;
}

.signin-button:hover {
    background-color: #007B9E;
}